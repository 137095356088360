import type { PlatformExport } from '@/pages/QuickCreate/hooks/usePlatformExports';
import type { DecoratedMagicEvent } from '@/pages/QuickCreate/types';
import { PlatformTypes } from '@/types/gql.generated';

export type LabelOptions = {
  success?: boolean;
  platform: PlatformTypes;
  label?: string;
  isMobileBreakpoint: boolean;
  preferredPlatform: PlatformTypes | null;
  isPinned?: boolean;
};

type SuccessOptions = Pick<
  LabelOptions,
  'isMobileBreakpoint' | 'platform' | 'preferredPlatform'
>;

export const getStandardLabel = (platform: PlatformTypes): string => {
  if (platform === PlatformTypes.AgendaHero) {
    return 'Add to Agenda Hero';
  }
  if (platform === PlatformTypes.AppleIcs) {
    return 'Add to Apple';
  }
  if (platform === PlatformTypes.Email) {
    return 'Email invite';
  }
  if (
    platform === PlatformTypes.GoogleIntegration ||
    platform === PlatformTypes.GoogleLink
  ) {
    return 'Add to Google';
  }
  if (platform === PlatformTypes.OfficeLink) {
    return 'Add to Office 365';
  }
  if (platform === PlatformTypes.OutlookLink) {
    return 'Add to Outlook';
  }

  return 'Add to calendar';
};

const getSuccessLabel = ({
  isMobileBreakpoint,
  platform,
  preferredPlatform,
}: SuccessOptions): string => {
  if (isMobileBreakpoint || preferredPlatform) {
    return '';
  }
  if (platform === PlatformTypes.Email) {
    return 'Sent! 🎉';
  }
  return 'Added! 🎉';
};

export const getLabel = (options: LabelOptions): string => {
  const { label, success } = options;

  if (label) {
    return label;
  }
  return success
    ? getSuccessLabel(options)
    : getStandardLabel(options.platform);
};

type SendState = Record<PlatformTypes, boolean>;

export const getInitialSendState = (): SendState => {
  return Object.values(PlatformTypes).reduce<
    Partial<Record<PlatformTypes, boolean>>
  >((idx, type) => ({ ...idx, [type]: false }), {}) as SendState;
};

export const isOtherPlatform = (platform: PlatformTypes): boolean => {
  return (
    platform !== PlatformTypes.AgendaHero && platform !== PlatformTypes.Email
  );
};

export const hasSentToOther = (
  sends: SendState,
  preferredPlatform: PlatformTypes | null
): boolean => {
  return Object.keys(sends).some((key) => {
    const platform = key as PlatformTypes;
    return (
      sends[platform] &&
      isOtherPlatform(platform) &&
      platform !== preferredPlatform
    );
  });
};

export const hasDrawer = (platform: PlatformTypes): boolean => {
  return [
    PlatformTypes.AgendaHero,
    PlatformTypes.Email,
    PlatformTypes.GoogleIntegration,
  ].includes(platform);
};

export const hasExported = (
  adds: PlatformExport[],
  entry: DecoratedMagicEvent,
  platform: PlatformTypes
): boolean => {
  return adds.some(
    (item) =>
      item.platform === platform &&
      item.entryExports.some((e) => e.entry.id === entry.id)
  );
};

export const hasExportedToOther = (
  adds: PlatformExport[],
  entry: DecoratedMagicEvent,
  preferredPlatform: PlatformTypes | null
): boolean => {
  const sends = adds
    .filter((platformExport) =>
      platformExport.entryExports.some((item) => item.entry.id === entry.id)
    )
    .reduce<SendState>((state, add) => {
      return {
        ...state,
        [add.platform]: true,
      };
    }, getInitialSendState());

  return hasSentToOther(sends, preferredPlatform);
};
