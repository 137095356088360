import { useRef, useState } from 'react';
import { config } from '@/config';
import { Box } from '@/ui';
import { Card } from '../../Card';

const withLogrocket = (html: string): string => {
  return `
    <script src="https://cdn.lrkt-in.com/LogRocket.min.js" crossorigin="anonymous"></script>
    <script>
    if (window.LogRocket) {
      window.LogRocket.init('${config.logrocket.key}', {
        release: '${config.version}-${config.release}',
        mergeIframes: true,
      });
    }
    </script>
    ${html}
  `;
};

type Props = {
  html: string;
};

export const IframePromptCard = ({ html }: Props) => {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const [height, setHeight] = useState(0);
  const srcDoc = config.logrocket.enabled ? withLogrocket(html) : html;

  const handleLoad = () => {
    const height = ref.current?.contentWindow?.document.body.scrollHeight;

    if (typeof height === 'number') {
      setHeight(height);
    }
  };

  return (
    <Card>
      <Box
        as="iframe"
        flex="1"
        height={height + 'px'}
        maxH="800px"
        minH="370px"
        ref={ref}
        srcDoc={srcDoc}
        onLoad={handleLoad}
      />
    </Card>
  );
};
