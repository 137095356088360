import { useCallback, useEffect, useState } from 'react';
import { useCurrentUserContext } from '@/contexts';
import { useCreateExperience } from '@/features/experiences';
import { getEditLink } from '@/features/experiences/lib/experience';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useLauncherAction } from '@/hooks/useLauncher';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import type { DecoratedMagicJob } from '@/pages/QuickCreate/types';
import { useAddContentModalStore } from '@/pages/Schedule/components';
import {
  Badge,
  Button,
  type ButtonProps,
  Icon,
  Icons,
  Link,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  Text,
  useDisclosure,
} from '@/ui';
import { MagicWand } from '@/ui/Icon/MagicWand';
import { useAuthModal } from '../../AuthModal';

type CreatedExperience = { id: string };

export const CreateExperienceButton = (props: ButtonProps) => {
  const { trackEvent } = useAnalytics();
  const { job } = useQuickCreateContext();
  const { createExperienceFromMagicJob } = useCreateExperience();
  const { open: openAddContentModal } = useAddContentModalStore();
  const { open: openAuthModal } = useAuthModal();
  const { isAuthenticated } = useCurrentUserContext();
  const [isLaunched, setIsLaunched] = useState(false);
  const {
    isOpen: isPopoverOpen,
    onOpen: openPopover,
    onClose: closePopover,
  } = useDisclosure();
  const [createdExperience, setCreatedExperience] =
    useState<CreatedExperience | null>(null);

  const create = useCallback(
    (job: DecoratedMagicJob) => {
      if (!job.id) {
        return;
      }

      createExperienceFromMagicJob(job.id, {
        onSuccess: (result) => {
          const experience = result.createExperience;
          setCreatedExperience(experience);
          openAddContentModal({ launchState: 'onboarding' });
          const newTab = window.open(getEditLink(experience), '_blank');
          if (!newTab) {
            openPopover();
          }
        },
      });
    },
    [createExperienceFromMagicJob, openAddContentModal, openPopover]
  );

  useLauncherAction('create-page', async () => {
    setIsLaunched(true);
  });

  useEffect(() => {
    if (isLaunched && job) {
      create(job);
    }
  }, [isLaunched, job, create]);

  const handleCreateExperience = () => {
    trackEvent('quick-create:click create-experience', { isAuthenticated });

    if (!isAuthenticated) {
      openAuthModal({
        reason: 'create-page-experience',
        launch: 'create-page',
        onSuccess: () => {
          if (job) {
            create(job);
          }
        },
      });
    } else if (job?.id) {
      create(job);
    }
  };

  return (
    <Popover
      isOpen={Boolean(isPopoverOpen && createdExperience)}
      onClose={closePopover}
    >
      <PopoverAnchor>
        <Button
          {...props}
          leftIcon={<MagicWand />}
          variant="magic"
          onClick={handleCreateExperience}
        >
          <Text as="span">Turn into shareable page</Text>
          <Badge
            color="brand.500"
            pos="relative"
            size="xs"
            top="-1"
            variant="text"
          >
            BETA
          </Badge>
        </Button>
      </PopoverAnchor>

      {createdExperience && (
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Text fontWeight="bold" mb="3">
              Page created 🎉
            </Text>
            <Text fontWeight="medium">
              Continue to your{' '}
              <Button
                as={Link}
                fontSize="1rem"
                href={getEditLink(createdExperience)}
                rightIcon={<Icon as={Icons.ArrowRight} />}
                target="_blank"
                variant="link"
              >
                new page
              </Button>
            </Text>
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
};
