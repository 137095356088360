import { useEffect, useState } from 'react';
import { Collapse } from '@/components/Collapse';
import { useCurrentUserContext } from '@/contexts';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useLauncherAction } from '@/hooks/useLauncher';
import { useIsSharePage } from '@/pages/QuickCreate/hooks/useIsSharePage';
import { usePlatformExports } from '@/pages/QuickCreate/hooks/usePlatformExports';
import { hasMultiExported } from '@/pages/QuickCreate/utils/googleCalendar';
import type { DecoratedIntegration } from '@/pages/Schedule/hooks/useIntegrations';
import { type Calendar, PlatformTypes } from '@/types/gql.generated';
import { useToast, Box, CloseButton, Flex, Spinner, Text, Grid } from '@/ui';
import { useQuickCreateContext } from '../../../contexts';
import { Card } from '../../Card';
import { LAUNCH_KEY } from '../constants';
import { GoogleCalendarButton } from '../GoogleCalendarButton';
import { GoogleCalendarPicker } from '../GoogleCalendarPicker';
import {
  useCurrentDestination,
  useExportMagicEvents,
  useMultiExportStore,
} from '../hooks';
import { useLegacyExportMagicEvents } from '../hooks/useLegacyExportMagicEvents';
import { usePickerIntegrations } from '../hooks/usePickerIntegrations';
import type { Destination } from '../types';
import { CreateExperienceButton } from './CreateExperienceButton';
import { IntegrationErrors } from './IntegrationErrors';
import { MultiExportModal } from './MultiExportModal';
import { SocialSharePopover } from './SocialSharePopover';
import { SuccessState } from './SuccessState';
import { useIsStuck } from './useIsStuck';

export const MultiExportCard = () => {
  const { job, page } = useQuickCreateContext();
  const { currentUser } = useCurrentUserContext();
  const { isSharePage } = useIsSharePage();
  const { integrations, isLoading: isLoadingIntegrations } =
    usePickerIntegrations();
  const { destination, setDestination } = useCurrentDestination();
  const { value: experiencesEnabled } = useFeatureFlag('experiencesEnabled');
  const toast = useToast();
  const { isStuck, containerRef } = useIsStuck();
  const [modalOpen, setModalOpen] = useState(false);
  const { exportMagicEvents, isPending: isExportingMagicEvents } =
    useExportMagicEvents();
  const { legacyExportMagicEvents, isPending: isExportingLegacy } =
    useLegacyExportMagicEvents();
  const {
    isOpen: isControlOpen,
    open: openControl,
    close: closeControl,
    toggle: toggleControl,
  } = useMultiExportStore();

  const isExporting = isExportingMagicEvents || isExportingLegacy;

  const { platformExports } = usePlatformExports();
  const [overrideSuccessState, setOverrideSuccessState] = useState(false);

  const entries = job?.events ?? [];
  const platformExport = platformExports.find(
    ({ platform }) => platform === destination?.platform
  );
  const hasExportedDestination = Boolean(
    destination && hasMultiExported(destination, platformExports, entries)
  );
  const hasAddedAll = Boolean(hasExportedDestination && platformExport);
  const showSuccessState =
    hasExportedDestination && platformExport && !overrideSuccessState;

  useEffect(() => {
    if (hasExportedDestination) {
      openControl();
    }
  }, [hasExportedDestination, openControl]);

  useEffect(() => {
    if (!currentUser) {
      closeControl();
    }
  }, [currentUser, closeControl]);

  useLauncherAction(LAUNCH_KEY, () => {
    setModalOpen(true);
    openControl();
  });

  const addToGoogleButtonText =
    entries.length > 1
      ? `Add all ${entries.length} to Google`
      : 'Add to Google';

  const handleAdd = (destination: Destination) => {
    const options = {
      onSuccess: () => setOverrideSuccessState(false),
      onError: () => {
        toast.error(
          'Whoops! We had some trouble sharing to your calendar. Please try again.'
        );
      },
    };
    if (isSharePage) {
      legacyExportMagicEvents(destination, entries, options);
    } else {
      exportMagicEvents(destination, entries, options);
    }
  };

  const handleClose = () => {
    setOverrideSuccessState(true);
    closeControl();
  };

  const handleReset = () => {
    setOverrideSuccessState(true);
  };

  const handleModalSelect = (
    integration: DecoratedIntegration,
    calendar: Calendar
  ) => {
    const destination: Destination = {
      calendar,
      integration,
      platform: PlatformTypes.GoogleIntegration,
    };
    setDestination(destination);
    handleAdd(destination);
    setModalOpen(false);
  };

  return (
    <>
      <MultiExportModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSelect={handleModalSelect}
      />

      <Box
        mt="-4"
        pos="sticky"
        ref={containerRef}
        top="-1px" // https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
        zIndex="10"
      >
        {/* This div sits underneath the card to prevent entry cards from bleeding underneath */}
        <Box
          bg={isStuck ? 'linear-gradient(#dbd2ee, #dcd3ef)' : 'transparent'}
          h="calc(var(--chakra-space-6) + 1px)" // account for the -1px adjustment above
          mb="-2"
        />

        <Card
          bg="#FAF5FA"
          direction="column"
          pt="4"
          px={{ base: 4, sm: 6 }}
          py="4"
          shadow="0px 0px 12px 0px rgba(152, 130, 206, 0.50)"
        >
          {isControlOpen ? (
            <Flex align="center" justify="space-between" minH="10" w="100%">
              <Text fontSize="22px" fontWeight="bold" textStyle="magic">
                Where shall we send your events?
              </Text>
              <CloseButton onClick={handleClose} />
            </Flex>
          ) : isSharePage ? (
            <Flex
              align={{ base: 'stretch', sm: 'center' }}
              direction={{ base: 'column', sm: 'row' }}
              gap="2"
              justify="space-between"
            >
              <Text fontSize="22px" fontWeight="bold" textStyle="magic">
                Voila! Your events
              </Text>

              <Flex
                align="center"
                direction={{ base: 'column-reverse', sm: 'row' }}
                flexGrow="1"
                gap="2"
                justify="flex-end"
              >
                {page?.socialSharingEnabled && (
                  <SocialSharePopover w={{ base: '100%', sm: 'auto' }} />
                )}

                {integrations.length > 0 ? (
                  <GoogleCalendarButton
                    w={{ base: '100%', sm: 'auto' }}
                    onClick={toggleControl}
                  >
                    {addToGoogleButtonText}
                  </GoogleCalendarButton>
                ) : (
                  <GoogleCalendarButton
                    isAuth
                    isLoading={isLoadingIntegrations}
                    job={job}
                    launch={LAUNCH_KEY}
                    w={{ base: '100%', sm: 'auto' }}
                  >
                    {addToGoogleButtonText}
                  </GoogleCalendarButton>
                )}
              </Flex>
            </Flex>
          ) : (
            <Flex
              align="center"
              direction={{ base: 'column', md: 'row' }}
              gap="2"
            >
              <Grid
                gap="2"
                w="100%"
                templateColumns={{
                  base: '1fr',
                  sm: !page?.socialSharingEnabled
                    ? 'repeat(2, minmax(0, 1fr))'
                    : '1fr',
                  md: 'repeat(2, minmax(0, 1fr))',
                  lg: page?.socialSharingEnabled
                    ? 'repeat(2, minmax(0, 1fr))'
                    : '1fr',
                  xl: 'repeat(2, minmax(0, 1fr))',
                }}
              >
                {experiencesEnabled && <CreateExperienceButton />}

                {integrations.length > 0 ? (
                  <GoogleCalendarButton onClick={toggleControl}>
                    {addToGoogleButtonText}
                  </GoogleCalendarButton>
                ) : (
                  <GoogleCalendarButton
                    isAuth
                    isLoading={isLoadingIntegrations}
                    job={job}
                    launch={LAUNCH_KEY}
                  >
                    {addToGoogleButtonText}
                  </GoogleCalendarButton>
                )}
              </Grid>
            </Flex>
          )}

          <Collapse
            display="flex"
            flexDirection="column"
            gap="3"
            in={isControlOpen}
            mt="2"
            overflow="hidden"
          >
            {!isLoadingIntegrations && (
              <IntegrationErrors onReportClick={toggleControl} />
            )}

            {isLoadingIntegrations ? (
              <Flex align="center" flex="1" gap="2">
                <Spinner size="sm" />
                Loading calendars
              </Flex>
            ) : showSuccessState ? (
              <SuccessState
                platformExport={platformExport}
                onResetClick={handleReset}
              />
            ) : integrations.length > 0 ? (
              <Flex align="center" flex="1" gap="2">
                <GoogleCalendarPicker
                  value={destination}
                  onChange={setDestination}
                />
                <GoogleCalendarButton
                  flexShrink="0"
                  isDisabled={!destination || hasAddedAll}
                  isLoading={isExporting}
                  onClick={() => destination && handleAdd(destination)}
                >
                  {hasAddedAll ? 'Added! 🎉' : 'Add'}
                </GoogleCalendarButton>
              </Flex>
            ) : null}
          </Collapse>
        </Card>
      </Box>
    </>
  );
};
