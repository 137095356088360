import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { TIME_ZONE_UTC } from '@/constants';
import { gqlClient } from '@/lib';
import type { DecoratedMagicEvent } from '@/pages/QuickCreate/types';
import {
  CreateEntrySource,
  type CreateEntryInput,
} from '@/types/gql.generated';
import { datePartInZone } from '@/utils/dates';
import type { QueryError } from '@/utils/errors';
import type {
  CreateMagicEntryMutation,
  CreateMagicEntryMutationVariables,
} from './useCreateMagicEntry.generated';

type ScheduleLike = {
  id: string;
  timeZone: string;
};

const query = gql`
  mutation CreateMagicEntry($input: CreateEntryInput!) {
    createEntry(input: $input) {
      id
    }
  }
`;

const createInput = (
  schedule: ScheduleLike,
  entry: DecoratedMagicEvent
): CreateEntryInput => {
  const { id } = schedule;
  const timeZone = entry.isAllDay ? TIME_ZONE_UTC : schedule.timeZone;

  const createRecurrence = (): CreateEntryInput['recurrences'][number] => {
    const startDate = entry.isAllDay
      ? datePartInZone(entry.startDate, TIME_ZONE_UTC).toISO()
      : entry.startDate.toUTC().toISO();

    const endDate = entry.isAllDay
      ? datePartInZone(entry.endDate, TIME_ZONE_UTC).toISO()
      : entry.endDate.toUTC().toISO();

    return {
      startDate,
      endDate,
      isOnDay: entry.isAllDay,
      rule: entry.rrule,
    };
  };

  return {
    scheduleId: id,
    title: entry.title,
    emoji: entry.emoji,
    locationWithPlace: entry.locationWithPlace,
    description: entry.description,
    timeZone,
    recurrences: [createRecurrence()],
    source: CreateEntrySource.Magic,
  };
};

export const useCreateMagicEntry = () => {
  const { mutate, ...rest } = useMutation<
    CreateMagicEntryMutation,
    QueryError,
    CreateEntryInput
  >({
    mutationFn: (input) => {
      return gqlClient.request<
        CreateMagicEntryMutation,
        CreateMagicEntryMutationVariables
      >(query, { input });
    },
  });

  const createMagicEntry = (
    schedule: ScheduleLike,
    entry: DecoratedMagicEvent,
    options?: MutateOptions<
      CreateMagicEntryMutation,
      QueryError,
      CreateEntryInput
    >
  ) => {
    const input = createInput(schedule, entry);
    return mutate(input, options);
  };

  return {
    ...rest,
    createMagicEntry,
  };
};
