import { useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { useOpenSettingsModal } from '@/components/SettingsModal';
import { usePinChangeTip } from '@/pages/QuickCreate/hooks/usePinChangeTip';
import type { DecoratedMagicEvent } from '@/pages/QuickCreate/types';
import { Button, Link, Text, type TooltipProps } from '@/ui';
import { getName } from '@/utils/platformLib';
import { MagicTooltip } from '../MagicTooltip';

type Props = TooltipProps & {
  entry?: DecoratedMagicEvent;
  showOnUnspecifiedEntry?: boolean;
};

export const PinSuccessTooltip = ({
  entry,
  showOnUnspecifiedEntry = false,
  ...props
}: Props) => {
  const tooltipRef = useRef(null);
  const {
    dismiss,
    isOpen: isPinChangeActive,
    platform: changedPlatform,
    relatedEntry,
  } = usePinChangeTip();
  const { openSettingsModal } = useOpenSettingsModal();
  const isEntryMatch = relatedEntry
    ? relatedEntry.id === entry?.id
    : showOnUnspecifiedEntry;
  const isOpen = isPinChangeActive && isEntryMatch;

  useClickAway(tooltipRef, () => dismiss());

  // Repaint tooltip on job load to account for prompt and entry content moving
  // the tooltip's target lower once it populates
  const [repaint, setRepaint] = useState(0);
  useEffect(() => {
    if (repaint === 0 && isOpen) {
      const observer = new MutationObserver(() => {
        setRepaint(repaint + 1);
      });

      observer.observe(document.getElementById('root') as HTMLElement, {
        subtree: true,
        childList: true,
      });

      return () => {
        observer.disconnect();
        setRepaint(0);
      };
    }
  }, [repaint, isOpen]);

  return (
    <MagicTooltip
      isDisabled={!isOpen}
      isOpen={isOpen}
      ref={tooltipRef}
      label={
        changedPlatform && (
          <Text pointerEvents="auto">
            {getName(changedPlatform)} is now your preferred platform! You can
            manage this in{' '}
            <Button
              as={Link}
              variant="link"
              onClick={() => openSettingsModal('calendars')}
            >
              settings
            </Button>
            .
          </Text>
        )
      }
      {...props}
    />
  );
};
