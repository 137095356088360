import { MotionFlex, type MotionFlexProps } from '@/ui';
import { forwardRef } from '@/ui';
import { useQuickCreateContext } from '../../contexts';
import type { DecoratedMagicEvent } from '../../types';
import { didJobUseFallback } from '../../utils/job';
import { MultiExportCard } from '../GoogleCalendar';
import { EntryCard } from './components/EntryCard';
import { FallbackNotice } from './components/FallbackNotice';

type Props = MotionFlexProps & {
  confirmDeleteEntry?: DecoratedMagicEvent | null;
  onDeleteClick?: (entry: DecoratedMagicEvent) => void;
};

export const EntryList = forwardRef(
  ({ confirmDeleteEntry, onDeleteClick, ...props }: Props, ref) => {
    const { job } = useQuickCreateContext();
    const showFallbackNotice = didJobUseFallback(job);

    return (
      <MotionFlex flexDir="column" gap="4" ref={ref} w="100%" {...props}>
        {showFallbackNotice && <FallbackNotice />}

        <MultiExportCard />

        {job?.events.map((entry, index) => (
          <EntryCard
            entry={entry}
            isConfirmingDelete={confirmDeleteEntry?.id === entry.id}
            isFirst={index === 0}
            key={entry.id}
            onDeleteClick={onDeleteClick}
          />
        ))}
      </MotionFlex>
    );
  }
);
