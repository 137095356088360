import {
  type ChangeEventHandler,
  type FormEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AppLoader } from '@/components/AppLoader';
import { useSchedules } from '@/hooks/useSchedules';
import { SuccessStat } from '@/pages/QuickCreate/components/SuccessStat';
import type { DecoratedMagicEvent } from '@/pages/QuickCreate/types';
import { Role } from '@/types/gql.generated';
import { useToast, Button, Flex, Link, Select, Text } from '@/ui';
import { useCreateMagicEntry } from './hooks';

type Props = {
  entry: DecoratedMagicEvent | undefined;
  onSuccess: () => void;
};

export const AddToAgendaHeroForm = ({ entry, onSuccess }: Props) => {
  const { data: schedules, isLoading: isLoadingSchedules } = useSchedules();
  const [scheduleId, setScheduleId] = useState('');
  const toast = useToast();
  const { createMagicEntry, data, reset, isPending, isSuccess } =
    useCreateMagicEntry();

  const schedulesWithPermissions = useMemo(() => {
    return (
      schedules?.allSchedules.filter((schedule) =>
        [Role.Owner, Role.Editor].includes(schedule.role)
      ) ?? []
    );
  }, [schedules]);

  // default the schedule select to the first schedule
  useEffect(() => {
    if (!scheduleId && schedulesWithPermissions.length) {
      setScheduleId(schedulesWithPermissions[0].id);
    }
  }, [schedulesWithPermissions, scheduleId, setScheduleId]);

  if (!entry) {
    return null;
  }

  const handleSelectChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    setScheduleId(event.target.value);
    reset();
  };

  const handleSubmit: FormEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();

    const schedule = schedules?.allSchedules.find(
      ({ id }) => id === scheduleId
    );
    if (schedule && entry) {
      createMagicEntry(schedule, entry, {
        onSuccess,
        onError: () => {
          toast.error(
            'Error adding this event to your schedule, please try again'
          );
        },
      });
    }
  };

  const submitButton = (
    <Button
      isDisabled={!scheduleId}
      isLoading={isPending}
      minW={{ md: '125px' }}
      type="submit"
    >
      Add
    </Button>
  );

  return (
    <>
      {isLoadingSchedules ? (
        <AppLoader my="3" />
      ) : schedulesWithPermissions.length ? (
        <Flex
          align="center"
          as="form"
          flex="1"
          gap="2"
          noValidate
          onSubmit={handleSubmit}
        >
          <Select
            bg="white"
            color="black"
            value={scheduleId}
            onChange={handleSelectChange}
          >
            {schedulesWithPermissions.map((schedule) => (
              <option key={schedule.id} value={schedule.id}>
                {schedule.title}
              </option>
            ))}
          </Select>
          {isSuccess && data ? (
            <SuccessStat
              cta="Go to event"
              href={`/schedule/${scheduleId}/item/${data.createEntry.id}?startDate=${entry.startDate.toISODate()}`}
              label="Added! 🎉"
            />
          ) : (
            submitButton
          )}
        </Flex>
      ) : (
        <Text textAlign="center">
          You don&apos;t have any Agenda Hero schedules!
          <br />
          <Link
            href="https://agendahero.com/auth?utm_source=magic_extension"
            target="_blank"
          >
            Create your first schedule
          </Link>
        </Text>
      )}
    </>
  );
};
