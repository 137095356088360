import { useMount } from 'react-use';
import { usePlatformPinTooltip } from '@/pages/QuickCreate/hooks/usePlatformPinTip';
import { Box, Flex, Text, type TooltipProps } from '@/ui';
import { MagicTooltip } from '../../MagicTooltip';

type Props = TooltipProps & {
  isActive: boolean;
};

export const PlatformPinTooltip = ({ isActive, ...props }: Props) => {
  const { isOpen, open, tooltipRef, shouldOpen } = usePlatformPinTooltip();

  useMount(() => {
    if (isActive && shouldOpen && !isOpen) {
      const openTimeout = setTimeout(open, 500);
      return () => clearTimeout(openTimeout);
    }
  });

  return (
    <MagicTooltip
      {...props}
      closeDelay={500}
      isDisabled={!isActive}
      isOpen={isOpen}
      ref={tooltipRef}
      label={
        <Flex align="center" gap="2">
          <Box fontSize="2xl">💡</Box>
          <Text>
            Tip! Pin your preferred platform
            <br /> to make it easier to access
          </Text>
        </Flex>
      }
    />
  );
};
