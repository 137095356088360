import {
  SegmentedControl as CommonSegmentedControl,
  type SegmentedControlOption,
  type SegmentedControlProps,
} from '@/pages/Schedule/components';
import { MagicJobType } from '@/types/gql.generated';

type Props = Omit<SegmentedControlProps<MagicJobType>, 'options'>;

export const SegmentedControl = (props: Props) => {
  const segments: SegmentedControlOption<MagicJobType>[] = [
    {
      label: 'Text',
      value: MagicJobType.Text,
    },
    {
      label: 'Image or PDF',
      value: MagicJobType.File,
    },
  ];

  return (
    <CommonSegmentedControl
      bg="white"
      border="1px solid rgba(0,0,0,0.08)"
      borderRadius="xl"
      flex="1"
      options={segments}
      p="1.5"
      _disabled={{
        opacity: 0.7,
      }}
      _selected={{
        bg: 'linear-gradient(90deg, rgba(143, 182, 246, 0.60) 0%, rgba(196, 41, 138, 0.12) 100%)',
        shadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.12)',
        outline: '1px solid rgba(0, 0, 0, 0.08)',
        outlineOffset: '-1px',
      }}
      {...props}
    />
  );
};
