import type { Editor } from '@tiptap/react';
import { clsx } from 'clsx';
import {
  type MouseEventHandler,
  type MutableRefObject,
  type ReactNode,
  forwardRef,
  useRef,
} from 'react';
import {
  RichTextEditor,
  type RichTextEditorProps,
} from '@/pages/Schedule/components';
import { Flex, useMergeRefs } from '@/ui';
import { useIsExtension } from '../../../hooks/useIsExtension';
import { Card, CardBody } from '../../Card';
import classes from './PromptTextArea.module.scss';

type Props = Omit<RichTextEditorProps, 'ref' | 'value'> & {
  containerRef?: MutableRefObject<HTMLDivElement | null>;
  value: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  footer?: ReactNode;
};

export const PromptTextArea = forwardRef<Editor, Props>(
  function PromptTextAreaWithRef(
    { isLoading, isDisabled, containerRef, className, footer, ...props },
    ref
  ) {
    const { isExtension } = useIsExtension();
    const internalContainerRef = useRef<HTMLDivElement>(null);
    const internalEditorRef = useRef<Editor | null>(null);

    const handleContainerClick: MouseEventHandler<HTMLElement> = (event) => {
      if (event.currentTarget.nodeName !== 'button') {
        internalEditorRef.current?.commands.focus();
      }
    };

    const editorClasses = clsx(className, classes.editor);

    return (
      <Card
        _focusWithin={{ borderColor: 'brand.500 !important' }}
        borderWidth="1px"
        cursor="text"
        flex="1"
        flexDir="column"
        maxH={isExtension ? '440px' : { base: '300px', md: '450px' }}
        minH="160px"
        ref={useMergeRefs(containerRef, internalContainerRef)}
        // set transition via sx so it doesn't conflict with framer-motion's transition property
        sx={{ transition: 'all 0.2s ease-in-out' }}
        onClick={handleContainerClick}
        {...(isDisabled && {
          opacity: 0.6,
          cursor: 'default',
        })}
      >
        <CardBody overflowY="auto" p="0">
          <RichTextEditor
            className={editorClasses}
            loader={null}
            readOnly={isLoading || isDisabled}
            ref={useMergeRefs(ref, internalEditorRef)}
            testId="prompt-input"
            {...props}
          />
        </CardBody>
        {footer && (
          <Flex
            align="center"
            justify="flex-end"
            pr={{ base: 3, md: 5 }}
            py="2"
          >
            {footer}
          </Flex>
        )}
      </Card>
    );
  }
);
