import type { Editor } from '@tiptap/react';
import { darken, lighten } from 'color2k';
import {
  type FormEventHandler,
  type MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AiDisabledAlert } from '@/components/AiDisabledAlert';
import { Collapse } from '@/components/Collapse';
import { FilePreview } from '@/components/FilePreview';
import { QuickCreateErrorMessage } from '@/components/QuickCreateErrorMessage';
import { UploadTarget } from '@/components/UploadTarget';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useIsBreakpoint } from '@/hooks/useIsBreakpoint';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import { MagicJobSource, MagicJobType } from '@/types/gql.generated';
import { MotionFlex, type MotionFlexProps } from '@/ui';
import { Button, Flex, Text, useMergeRefs } from '@/ui';
import { shakeElement } from '@/utils/shakeElement';
import { hasFilePrompt } from '../../utils/prompt';
import { Card } from '../Card';
import { FadeInOut } from '../FadeInOut';
import { LoadingText } from '../LoadingText';
import { IframePromptCard } from './components/IframePromptCard';
import { PromptTextArea } from './components/PromptTextArea';
import { SegmentedControl } from './components/SegmentedControl';
import { SubmitButton } from './components/SubmitButton';

type Props = Omit<MotionFlexProps, 'onSubmit'> & {
  editorRef?: MutableRefObject<Editor | null>;
  submitButtonAlwaysVisible?: boolean;
  onSubmit: () => void;
  onPromptChange?: () => void;
};

export const PromptForm = ({
  editorRef,
  submitButtonAlwaysVisible,
  onSubmit,
  ...props
}: Props) => {
  const {
    state,
    job,
    prompt,
    promptType,
    setPrompt,
    setPromptType,
    instructions,
    setInstructions,
    createMutation,
    resetAppState,
  } = useQuickCreateContext();
  const { value: disableAllAiFeatures } = useFeatureFlag(
    'disableAllAiFeatures'
  );
  const promptRef = useRef<HTMLDivElement | null>(null);
  const internalEditorRef = useRef<Editor | null>(null);
  const uploadRef = useRef<HTMLDivElement | null>(null);
  const editorRefs = useMergeRefs(editorRef, internalEditorRef);
  const { trackEvent } = useAnalytics();
  const isSmBreakpoint = useIsBreakpoint('sm');
  const isMdBreakpoint = useIsBreakpoint('md');
  const isLoading = state === 'loading';

  const [forceShowSubmitButton, setForceShowSubmitButton] = useState(false);
  useEffect(() => {
    if (!job) {
      setForceShowSubmitButton(false);
    }
  }, [job]);

  let showSubmitButton = true;
  if (forceShowSubmitButton || submitButtonAlwaysVisible) {
    showSubmitButton = true;
  } else if (isLoading || job) {
    showSubmitButton = false;
  } else if (promptType === MagicJobType.File && !hasFilePrompt(prompt)) {
    showSubmitButton = false;
  }

  const handleTextChange = (html: string, text: string) => {
    setPrompt({ html, text });
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    createMutation.reset();

    if (promptType === MagicJobType.Text) {
      if (!prompt.text.trim().length) {
        await shakeElement(promptRef);
        internalEditorRef.current?.commands.focus();
      } else {
        onSubmit();
        internalEditorRef.current?.commands.blur();
      }
    }

    if (promptType === MagicJobType.File) {
      if (!prompt.file) {
        await shakeElement(uploadRef);
      } else {
        onSubmit();
      }
    }
  };

  const handleFocus = () => {
    if (job) {
      setForceShowSubmitButton(true);
    }
  };

  const handleExampleClick = () => {
    const prompt = `
      <p>Hey Hero! Here’s a list of events happening this weekend:</p>
      <ul>
        <li>Rooftop Happy Hour, Fri 5pm: Unwind at our rooftop bar with a picturesque cityscape backdrop.</li>
        <li>Gourmet Dinner in the Sky, Sat 6-8pm: Elevated dining with city views and fine cuisine.</li>
        <li>Echoing Caves Music Festival, Sun 11am-5pm: Diverse musical acts in acoustic caves.</li>
      </ul>
      `;
    internalEditorRef.current?.commands.setContent(prompt);
    setPrompt({ html: prompt, text: prompt });
    trackEvent('quick-create:click example');
  };

  const handleClearClick = () => {
    resetAppState();
    setInstructions('');
    trackEvent('quick-create:click again');
  };

  const handleFileSelect = (file: File | null) => {
    setPrompt({ file });

    // force the submit button to appear when selecting a new file while
    // viewing the results of a previous job
    if (job) {
      setForceShowSubmitButton(true);
    }
  };

  const handleDropText = (text: string) => {
    setPrompt({ html: text, text });
    setPromptType(MagicJobType.Text);
  };

  const handleDragOver = () => {
    if (state === 'default') {
      setPromptType(MagicJobType.File);
    }
  };

  return (
    <MotionFlex
      as="form"
      direction="column"
      gap="2"
      onDragOver={handleDragOver}
      // @ts-expect-error - onSubmit is a valid prop with `as`
      onSubmit={handleSubmit}
      {...props}
    >
      {disableAllAiFeatures && <AiDisabledAlert />}

      <Collapse in={createMutation.isError}>
        {createMutation.error && (
          <QuickCreateErrorMessage
            error={createMutation.error}
            type={job?.type}
          />
        )}
      </Collapse>

      <Collapse in={state === 'default'}>
        <SegmentedControl
          isDisabled={isLoading || disableAllAiFeatures}
          value={promptType}
          onChange={setPromptType}
        />
      </Collapse>

      <Flex direction="column" gap="2">
        <FadeInOut key={promptType}>
          {job?.source === MagicJobSource.Email && job.sourceText ? (
            <IframePromptCard html={job.sourceText} />
          ) : promptType === MagicJobType.Text ? (
            <PromptTextArea
              containerRef={promptRef}
              isDisabled={disableAllAiFeatures}
              isLoading={isLoading}
              placeholder="Type or paste date, time, full paragraphs, links, etc!"
              ref={editorRefs}
              value={prompt.html}
              footer={
                !isLoading &&
                state === 'default' && (
                  <Button
                    colorScheme="dark"
                    isDisabled={isLoading || disableAllAiFeatures}
                    textDecor="underline"
                    variant="link"
                    {...(prompt.text.length > 0
                      ? {
                          onClick: handleClearClick,
                          children: <span>Clear</span>,
                        }
                      : {
                          onClick: handleExampleClick,
                          children: <span>Try example</span>,
                        })}
                  />
                )
              }
              onChange={handleTextChange}
              onFocus={handleFocus}
            />
          ) : state === 'edit' && prompt.file ? (
            <Card direction="column" gap="4" justify="center" p="5">
              <FilePreview
                file={prompt.file}
                pdfWidth={isSmBreakpoint ? 200 : isMdBreakpoint ? 400 : 500}
              />

              {instructions && <Text fontWeight="medium">{instructions}</Text>}
            </Card>
          ) : (
            <UploadTarget
              bg="white"
              borderColor={prompt.file ? 'transparent' : '#c7b5e8'}
              borderRadius="xl"
              file={prompt.file}
              instructions={instructions}
              isDisabled={disableAllAiFeatures || isLoading}
              ref={uploadRef}
              w="100%"
              dragOverProps={{
                bg: lighten('#c7b5e8', 0.1),
                borderColor: darken('#c7b5e8', 0.2),
              }}
              onDropText={handleDropText}
              onFocus={handleFocus}
              onInstructionsChange={setInstructions}
              onSelectFile={handleFileSelect}
            />
          )}
        </FadeInOut>

        <Collapse in={showSubmitButton}>
          <SubmitButton
            isDisabled={isLoading || disableAllAiFeatures}
            textAlign={isLoading ? 'left' : 'center'}
            onClick={() => trackEvent('quick-create:click generate')}
          >
            {isLoading ? (
              <LoadingText w="170px">🔮 Working our magic</LoadingText>
            ) : (
              '🪄 Create'
            )}
          </SubmitButton>
        </Collapse>

        {state === 'edit' && (
          <Button
            colorScheme="dark"
            fontSize="md"
            isDisabled={isLoading || disableAllAiFeatures}
            mt={{ base: 2, md: 1 }}
            textDecor="underline"
            variant="link"
            onClick={handleClearClick}
          >
            Clear and start over
          </Button>
        )}
      </Flex>
    </MotionFlex>
  );
};
