import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import type { SendEventInput } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import type {
  SendEventMutation,
  SendEventMutationVariables,
} from './useSendEvent.generated';

const query = gql`
  mutation SendEvent($input: SendEventInput!) {
    sendEvent(input: $input)
  }
`;

export const useSendEvent = () => {
  const { mutate, ...rest } = useMutation<
    SendEventMutation,
    QueryError,
    SendEventMutationVariables
  >({
    mutationFn: (variables) => gqlClient.request(query, variables),
  });

  const sendEvent = useCallback(
    (
      eventId: string,
      email: string,
      options?: MutateOptions<
        SendEventMutation,
        QueryError,
        SendEventMutationVariables
      >
    ) => {
      const input: SendEventInput = { eventId, email };
      return mutate({ input }, options);
    },
    [mutate]
  );

  return { sendEvent, ...rest };
};
