import { EmailIcon } from '@chakra-ui/icons';
import {
  type ChangeEventHandler,
  type FormEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useIsSharePage } from '@/pages/QuickCreate/hooks/useIsSharePage';
import { useLastSendToEmail } from '@/pages/QuickCreate/hooks/useLastSendToEmail';
import { useSendEvent } from '@/pages/QuickCreate/hooks/useSendEvent';
import { useSendQuickEntry } from '@/pages/QuickCreate/hooks/useSendQuickEntry';
import type { DecoratedMagicEvent } from '@/pages/QuickCreate/types';
import {
  useToast,
  Button,
  Text,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from '@/ui';
import { shakeElement } from '@/utils/shakeElement';
import { isValidEmail } from '@/utils/validation';

type Props = {
  entry: DecoratedMagicEvent | undefined;
  onSuccess: () => void;
};

export const EmailForm = ({ entry, onSuccess }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { trackEvent } = useAnalytics();
  const { isSharePage } = useIsSharePage();
  const {
    sendQuickEntry,
    reset: resetSendQuickEntry,
    isPending: sendQuickEntryPending,
    isSuccess: sendQuickEntrySuccess,
  } = useSendQuickEntry();
  const {
    sendEvent,
    reset: resetSendEvent,
    isPending: sendEventPending,
    isSuccess: sendEventSuccess,
  } = useSendEvent();
  const { lastEmail, setLastEmail } = useLastSendToEmail();
  const [email, setEmail] = useState(lastEmail);
  const toast = useToast();

  const isPending = sendQuickEntryPending || sendEventPending;
  const isSuccess = sendQuickEntrySuccess || sendEventSuccess;
  const reset = resetSendQuickEntry || resetSendEvent;

  useEffect(() => {
    if (isSuccess) {
      setEmail(lastEmail);
    }
  }, [isSuccess, lastEmail]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (isValidEmail(email)) {
      setLastEmail(email);

      if (!entry) {
        return;
      }

      const options = {
        onSuccess,
        onError: () => toast.error('Error sending event, please try again'),
      };

      if (isSharePage) {
        sendQuickEntry(entry.id, email, options);
      } else {
        sendEvent(entry.id, email, options);
      }
    } else {
      shakeElement(containerRef);
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const email = event.currentTarget.value;
    setEmail(email);

    if (email.length > 0 && isSuccess) {
      reset();
    }
  };

  return (
    // @ts-expect-error - onSubmit is a valid prop with `as`
    <Flex align="center" as="form" gap="2" onSubmit={handleSubmit}>
      <InputGroup ref={containerRef}>
        <InputLeftElement pointerEvents="none">
          <EmailIcon color="gray.300" />
        </InputLeftElement>
        <Input
          _hover={{ borderColor: 'gray.500' }}
          _placeholder={{ fontSize: 'lg', fontWeight: 'medium' }}
          bg="white"
          placeholder="Your email"
          type="email"
          value={email}
          onChange={handleChange}
        />
      </InputGroup>

      {isSuccess ? (
        <Text fontSize="sm" fontWeight="medium" minW="125px" textAlign="center">
          Invite sent! 🎉
        </Text>
      ) : (
        <Button
          isLoading={isPending}
          minW={{ md: '125px' }}
          type="submit"
          onClick={() => trackEvent('quick-create:click send')}
        >
          Send
        </Button>
      )}
    </Flex>
  );
};
