import { MagicJobStatus } from '@/types/gql.generated';
import type { DecoratedMagicJob } from '../types';

/** Did the job error out and we fell back to a single event on today's date? */
export const didJobUseFallback = (
  job: DecoratedMagicJob | undefined | null
): boolean => {
  return (
    !!job?.errorCode &&
    (job.status === MagicJobStatus.Completed ||
      job.status === MagicJobStatus.Processed)
  );
};
