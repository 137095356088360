import { clsx } from 'clsx';
import type { DecoratedMagicEvent } from '@/pages/QuickCreate/types';
import {
  RichTextEditor,
  type RichTextEditorProps,
} from '@/pages/Schedule/components';
import { Flex, type FlexProps } from '@/ui';
import type { QuickEntryChangeHandler } from '../../types';
import classes from './styles.module.scss';

type Props = Omit<FlexProps, 'onChange'> & {
  readOnly?: boolean;
  entry: DecoratedMagicEvent;
  onChange: QuickEntryChangeHandler;
  onCreate?: RichTextEditorProps['onCreate'];
};

export const Description = ({
  readOnly,
  entry,
  onChange,
  onCreate,
  ...props
}: Props) => {
  const classNames = clsx(classes.editor, {
    [classes.readOnly]: readOnly,
  });

  return (
    <Flex lineHeight="24px" {...props}>
      <RichTextEditor
        className={classNames}
        placeholder="Description"
        readOnly={readOnly}
        value={entry.description ?? ''}
        onChange={(description) => onChange({ description })}
        onCreate={onCreate}
      />
    </Flex>
  );
};
